<script setup>
import {useRouter} from "vue-router";
import "animate.css"
import {computed, onMounted, ref} from "vue";
import {store} from "@/store/store";
import GameProgressBar from "@/components/GameProgressBar.vue";
import party from "party-js";

const router = useRouter();
const wordDiv = ref(null);
const debugString = ref("");
const wordAnimationClass = ref("animate__fadeIn")

const onWordPronounced = (word) => {
  if (store.state.currentWordCompleted) {
    return
  }
  store.commit('onWordPronounced', word) // may result in currentWordCompleted
  if (!store.state.currentWordCompleted) {
    return; // nothing to animate
  }

  // current word guessed
  party.confetti(wordDiv.value)
  setTimeout(() => wordAnimationClass.value = "animate__tada text-primary", 200)

  // if end game
  if (store.state.currentWordIndex >= store.state.words.length - 1 && store.state.currentWordCompleted) {
    setTimeout(() => router.push('/end-game'), 1000)
    return
  }
  // otherwise, next word
  setTimeout(() => {
    wordAnimationClass.value = "animate__fadeOutDown animate__fast text-primary"
    setTimeout(() => {
      store.commit('nextWord')
      wordAnimationClass.value = "animate__bounceIn"
    }, 1000)
  }, 2000)
}

let onRecognitionResult = (event) => {
  for (let result of event.results) {
    for (let alternative of result) {
      const spokenWords = alternative.transcript.trim().toLowerCase().split(/[ \t-]+/).filter(w => w.length > 0)
      for (let spokenWord of spokenWords) {
        onWordPronounced(spokenWord)
      }
    }
  }
}

onMounted(() => {
  const numWords = store.state.words.length;
  if (!store.state.recognition || store.state.currentWordIndex >= numWords) {
    router.push('/');
    return
  }
  store.commit('setRecognitionCallbacks', {
    onStart: () => console.log("onStart"),
    onError: (event) => debugString.value = "onError" + JSON.stringify(event),
    onEnd: () => {
      store.commit('toggleRecognition', true);
      console.log("force start");
    },
    onResult: onRecognitionResult,
  });
});

</script>

<template>
  <div class="container mx-auto flex flex-col items-center space-y-24 rounded-2xl">
    <GameProgressBar/>
    <div class="text-xl text-neutral-500">
        {{ store.state.currentWordCompleted ? "Ура!" : "Прочитай вслух: " }}
    </div>

    <div class="text-6xl font-bold font-sans p-6 rounded-2xl animate__animated" :class="wordAnimationClass" ref="wordDiv">
      {{ store.getters.currentWord }}
    </div>

    <div class="text-neutral-500 text-sm text-center px-2">
      {{ store.state.wordPronounced }}
    </div>

    <div class="text-neutral-500 text-sm text-center">
      {{debugString}}
    </div>
  </div>

  <!-- debug -->
<!--
  <button
      class="btn btn-primary btn-sm m-3"
      @click="onWordPronounced('next')">pronounce 'next'</button>
  <button
      class="btn btn-primary btn-sm m-3"
      @click="onWordPronounced(store.getters.currentWord)">pronounce '{{store.getters.currentWord}}'</button>
-->
</template>
