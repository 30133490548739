import { createRouter, createWebHashHistory } from 'vue-router'
import MainMenu from "@/views/MainMenu.vue";
import Game from "@/views/Game.vue";
import VoiceSetup from "@/views/VoiceSetup.vue";
import EndGame from "@/views/EndGame.vue";

const routes = [
  {
    path: '/',
    name: 'main',
    component: MainMenu
  },
  {
    path: '/game',
    name: 'game',
    component: Game
  },
  {
    path: '/voice-setup',
    name: 'voice-setup',
    component: VoiceSetup
  },
  {
    path: '/end-game',
    name: 'end-game',
    component: EndGame
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
