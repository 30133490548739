import { createStore } from 'vuex'

export const RecognitionStatus = {
    NotStarted: 0,
    Active: 1,
    Stopped: 2,
    Error: 3
};

export const store = createStore({
    strict: true,
    state () {
        return {
            // Global settings
            lang: "ru-RU",
            difficulty: 0, // 0 - easy, 1 - medium, 2 - hard
            numberOfWords: 3,

            // Voice recognition
            recognitionStatus: RecognitionStatus.NotStarted,
            recognition: null, // SpeechRecognition() object

            // Game
            currentWordIndex: 0,
            words: [],
            currentWordCompleted: false,
            wordPronounced: ""
        }
    },
    getters: {
        recognitionStatusIcon (state) {
            switch (state.recognitionStatus) {
                case RecognitionStatus.NotStarted: return "-"
                case RecognitionStatus.Active:     return "REC"
                case RecognitionStatus.Stopped:    return "-"
                case RecognitionStatus.Error:      return "error"
                default:                           return "?"
            }
        },
        currentWord (state) {
            return state.words[state.currentWordIndex] || ""
        },
    },
    mutations: {
        setLang(state, lang) {
            state.lang = lang
            state.recognition = null
        },
        setDifficulty(state, d) {
            state.difficulty = parseInt(d)
        },
        setNumberOfWords(state, n) {
            state.numberOfWords = n
        },
        initRecognition(state) {
            state.recognition = new SpeechRecognition()
            if (!state.recognition) {
                return
            }
            state.recognition.lang = state.lang
            state.recognition.interimResults = true;
            state.recognition.continuous = true;
            this.commit('setRecognitionCallbacks', {})
        },
        destroyRecognition(state) {
            if (!state.recognition) {
                return
            }
            state.recognition.stop()
            state.recognition = null
        },
        _setStatus(state, status) {
            state.recognitionStatus = status
        },

        // callbacks: {onStart, onResult, onEnd, onError}, each may be undefined
        setRecognitionCallbacks(state, callbacks) {
            if (!state.recognition) {
                return
            }
            state.recognition.onstart = () => {
                this.commit('_setStatus', RecognitionStatus.Active)
                callbacks.onStart && callbacks.onStart()
            }
            state.recognition.onresult = (event) => {
                callbacks.onResult && callbacks.onResult(event)
            }
            state.recognition.onend = () => {
                this.commit('_setStatus', RecognitionStatus.Stopped)
                callbacks.onEnd && callbacks.onEnd()
            }
            state.recognition.onerror = (event) => {
                this.commit('_setStatus', RecognitionStatus.Error)
                callbacks.onError && callbacks.onError(event)
            }
        },
        toggleRecognition(state, on) {
            if (!state.recognition) {
                return
            }
            if (on && state.recognitionStatus !== RecognitionStatus.Active) {
                state.recognition.start()
            } else if (state.recognitionStatus === RecognitionStatus.Active) {
                state.recognition.stop()
            }
        },
        setWords(state, words) {
            state.words = words
            state.currentWordIndex = 0
            state.currentWordCompleted = false
            state.wordPronounced = ""
        },
        onWordPronounced(state, word) {
            word = word.trim().toLowerCase()
            state.wordPronounced = word
            const currentWord = state.words[state.currentWordIndex] || ""
            if (currentWord === word) {
                state.currentWordCompleted = true
            }
        },
        nextWord(state) {
            state.currentWordIndex++
            state.currentWordCompleted = false
        }
    },
    actions: {
    }
})
