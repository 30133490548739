<script setup>
import {useRouter} from "vue-router";
import {computed, onMounted, ref} from "vue";
import {store} from "@/store/store";
import GameProgressBar from "@/components/GameProgressBar.vue";
import party from "party-js";

const router = useRouter();

onMounted(() => {
  store.commit('destroyRecognition')
  if (store.state.words.length === 0) {
    router.push('/');
  }
});

</script>

<template>
  <div class="container mx-auto flex flex-col items-center space-y-9">
    <GameProgressBar/>
    <div class="animate__animated animate__lightSpeedInRight">
      <img src="img/game-over/win3.webp" class="w-full cursor-pointer" @click="router.push('/')"/>
    </div>
    <button class="btn btn-primary btn-wide m-5 btn-lg" @click="router.push('/')">
      restart
    </button>
  </div>

</template>
