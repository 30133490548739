<script setup>

import {useRouter} from "vue-router";
import MainSettings from "@/components/MainSettings.vue";
import {onMounted} from "vue";
import {store} from "@/store/store";

const router = useRouter();
onMounted(() => {
  store.commit('toggleRecognition', false)
})

</script>

<template>
  <MainSettings/>
  <div class="flex justify-center">
    <button class="btn btn-primary btn-wide m-5 btn-lg" @click="router.push('/voice-setup')">
      start
    </button>
  </div>
</template>