<script setup>
import {store} from "@/store/store";
import {computed, ref, watch} from 'vue';

const languages = [
  { code: 'en-US', name: '🇺🇸 English (United States)' },
  { code: 'en-GB', name: '🇬🇧 English (United Kingdom)' },
  { code: 'ru-RU', name: '🇷🇺 Русский' },
];

const difficultyLevels = [
  { index: 0, name: "👶 Easy" },
  { index: 1, name: "🤨 Medium" },
  { index: 2, name: "🫣 Hard" },
];

const gameDurations = [3, 5, 7, 10];
</script>

<template>
  <div class="p-4 space-y-4 bg-white shadow-lg rounded-lg animate__animated animate__fadeIn animate__faster">
    <div class="form-control">
      <label class="label">
        <span class="label-text font-semibold">Language</span>
      </label>
      <select :value="store.state.lang" @change="store.commit('setLang', $event.target.value)" class="select select-bordered">
        <option v-for="lang in languages" :key="lang.code" :value="lang.code">
          {{ lang.name }}
        </option>
      </select>
    </div>

    <div class="form-control">
      <label class="label">
        <span class="label-text font-semibold">Difficulty</span>
      </label>
      <select :value="store.state.difficulty" @input="store.commit('setDifficulty', $event.target.value)" class="select select-bordered">
        <option v-for="level in difficultyLevels" :key="level.index" :value="level.index">
          {{ level.name }}
        </option>
      </select>
    </div>

    <div class="form-control">
      <label class="label">
        <span class="label-text font-semibold">Number of words</span>
      </label>
      <select :value="store.state.numberOfWords" @input="store.commit('setNumberOfWords', $event.target.value)" class="select select-bordered">
        <option v-for="n in gameDurations" :key="n" :value="n">
          {{ n }}
        </option>
      </select>
    </div>
  </div>
</template>
