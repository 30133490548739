<script setup>

import {useRouter} from "vue-router";
import {RecognitionStatus, store} from "@/store/store";
import {onMounted, ref} from "vue";
import {wordSets} from "@/js/wordsets";
import {shuffle} from "@/js/helpers";

const router = useRouter();
const message = ref("Please allow microphone access to start the game.");

const onVoiceAllowed = () => {
  const words = wordSets[store.state.lang.split('-')[0]].filter(w => {
    if (store.state.difficulty === 0) {
      return w.length <= 4
    }
    if (store.state.difficulty === 1) {
      return w.length > 4 && w.length <= 6
    }
    if (store.state.difficulty === 2) {
      return w.length > 6
    }
    console.error("unknown difficulty level: ", store.state.difficulty)
    return false
  })
  const numWordsToPick = Math.min(words.length, store.state.numberOfWords)
  // shuffle and truncate; use shuffle(arr) func
  shuffle(words)
  words.splice(numWordsToPick)
  store.commit('setWords', words)

  router.push('/game')
}

onMounted(() => {
  if (!store.state.recognition) {
    window.SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    store.commit('initRecognition')
    if (store.state.recognition === null) {
      message.value = "no speech recognition available";
      return
    }
    store.commit('setRecognitionCallbacks',
        {
          'onStart': onVoiceAllowed,
          'onError': (event) => message.value = "error: " + event.error
        }
    )
  } else if (store.state.recognitionStatus === RecognitionStatus.Active) {
    onVoiceAllowed()
  }
  store.commit('toggleRecognition', true) // turn ON, it will request permission, then call onVoiceAllowed
})
</script>

<template>
  <div class="flex items-center">
    <div class="p-2">
      {{ message }}
    </div>
  </div>
</template>