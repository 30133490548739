<script setup>

import {store} from "@/store/store";

const stepClass = (i) => {
  if (i <= store.state.currentWordIndex) {
    return "step-primary"
  }
  return ""
}

const dataContent = (i) => {
  return (i < store.state.currentWordIndex) || (i === store.state.currentWordIndex && store.state.currentWordCompleted) ? "★" : ""
}

</script>

<template>
  <div class="overflow-x-auto">
    <ul class="steps steps-horizontal">
      <li
          v-for="(word, index) in store.state.words"
          :data-content="dataContent(index)"
          :key="word"
          class="step"
          :class="stepClass(index)"/>
    </ul>
  </div>
</template>

<style scoped>

</style>